import React from "react"
import RacepageNew from "../components/racepageNew"

const ParisNice2021 = () => {
  const startlist = [
    {
      number: " 227",
      name: " VERVAEKE Louis",
      team: " Alpecin-Fenix",
    },
    {
      number: " 226",
      name: " THWAITES Scott",
      team: " Alpecin-Fenix",
    },
    {
      number: " 225",
      name: " SBARAGLI Kristian",
      team: " Alpecin-Fenix",
    },
    {
      number: " 224",
      name: " RIESEBEEK Oscar",
      team: " Alpecin-Fenix",
    },
    {
      number: " 223",
      name: " LEYSEN Senne",
      team: " Alpecin-Fenix",
    },
    {
      number: " 222",
      name: " DE BONDT Dries",
      team: " Alpecin-Fenix",
    },
    {
      number: " 221",
      name: " PHILIPSEN Jasper",
      team: " Alpecin-Fenix",
    },
    {
      number: " 217",
      name: " PACHER Quentin",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 216",
      name: " LEMOINE Cyril",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 215",
      name: " LECROQ Jérémy",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 214",
      name: " HIVERT Jonathan",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 213",
      name: " GAUTIER Cyril",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 212",
      name: " BARTHE Cyril",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 211",
      name: " COQUARD Bryan",
      team: " B&B Hotels p/b KTM",
    },
    {
      number: " 207",
      name: " VAN POPPEL Danny",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 206",
      name: " VAN POPPEL Boy",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 205",
      name: " VAN DER HOORN Taco",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 204",
      name: " TAARAMÄE Rein",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 203",
      name: " KOCH Jonas",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 202",
      name: " EIKING Odd Christian",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 201",
      name: " MEINTJES Louis",
      team: " Intermarché - Wanty - Gobert Matériaux",
    },
    {
      number: " 197",
      name: " ZABEL Rick",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 196",
      name: " VANMARCKE Sep",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 195",
      name: " GREIPEL André",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 194",
      name: " BRÄNDLE Matthias",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 193",
      name: " BEVIN Patrick",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 192",
      name: " BARBIER Rudy",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 191",
      name: " NEILANDS Krists",
      team: " Israel Start-Up Nation",
    },
    {
      number: " 187",
      name: " ROJAS José Joaquín",
      team: " Movistar Team",
    },
    {
      number: " 186",
      name: " MÜHLBERGER Gregor",
      team: " Movistar Team",
    },
    {
      number: " 185",
      name: " MORA Sebastián",
      team: " Movistar Team",
    },
    {
      number: " 184",
      name: " JACOBS Johan",
      team: " Movistar Team",
    },
    {
      number: " 183",
      name: " ERVITI Imanol",
      team: " Movistar Team",
    },
    {
      number: " 182",
      name: " ARCAS Jorge",
      team: " Movistar Team",
    },
    {
      number: " 181",
      name: " JORGENSON Matteo",
      team: " Movistar Team",
    },
    {
      number: " 177",
      name: " VUILLERMOZ Alexis",
      team: " Team Total Direct Energie",
    },
    {
      number: " 176",
      name: " VAN GESTEL Dries",
      team: " Team Total Direct Energie",
    },
    {
      number: " 175",
      name: " TURGIS Anthony",
      team: " Team Total Direct Energie",
    },
    {
      number: " 174",
      name: " LAWLESS Chris",
      team: " Team Total Direct Energie",
    },
    {
      number: " 173",
      name: " DOUBEY Fabien",
      team: " Team Total Direct Energie",
    },
    {
      number: " 172",
      name: " BOASSON HAGEN Edvald",
      team: " Team Total Direct Energie",
    },
    {
      number: " 171",
      name: " LATOUR Pierre",
      team: " Team Total Direct Energie",
    },
    {
      number: " 167",
      name: " MÄDER Gino",
      team: " Bahrain - Victorious",
    },
    {
      number: " 166",
      name: " HAUSSLER Heinrich",
      team: " Bahrain - Victorious",
    },
    {
      number: " 165",
      name: " HALLER Marco",
      team: " Bahrain - Victorious",
    },
    {
      number: " 164",
      name: " HAIG Jack",
      team: " Bahrain - Victorious",
    },
    {
      number: " 163",
      name: " BAUHAUS Phil",
      team: " Bahrain - Victorious",
    },
    {
      number: " 162",
      name: " ARASHIRO Yukiya",
      team: " Bahrain - Victorious",
    },
    {
      number: " 161",
      name: " TEUNS Dylan",
      team: " Bahrain - Victorious",
    },
    {
      number: " 157",
      name: " TRENTIN Matteo",
      team: " UAE-Team Emirates",
    },
    {
      number: " 156",
      name: " OLIVEIRA Rui",
      team: " UAE-Team Emirates",
    },
    {
      number: " 155",
      name: " MCNULTY Brandon",
      team: " UAE-Team Emirates",
    },
    {
      number: " 154",
      name: " DE LA CRUZ David",
      team: " UAE-Team Emirates",
    },
    {
      number: " 153",
      name: " COSTA Rui",
      team: " UAE-Team Emirates",
    },
    {
      number: " 152",
      name: " BYSTRØM Sven Erik",
      team: " UAE-Team Emirates",
    },
    {
      number: " 151",
      name: " KRISTOFF Alexander",
      team: " UAE-Team Emirates",
    },
    {
      number: " 147",
      name: " TOUZÉ Damien",
      team: " AG2R Citroën Team",
    },
    {
      number: " 146",
      name: " PARET-PEINTRE Aurélien",
      team: " AG2R Citroën Team",
    },
    {
      number: " 145",
      name: " O'CONNOR Ben",
      team: " AG2R Citroën Team",
    },
    {
      number: " 144",
      name: " NAESEN Oliver",
      team: " AG2R Citroën Team",
    },
    {
      number: " 143",
      name: " GODON Dorian",
      team: " AG2R Citroën Team",
    },
    {
      number: " 142",
      name: " DEWULF Stan",
      team: " AG2R Citroën Team",
    },
    {
      number: " 141",
      name: " JUNGELS Bob",
      team: " AG2R Citroën Team",
    },
    {
      number: " 137",
      name: " KONYCHEV Alexander",
      team: " Team BikeExchange",
    },
    {
      number: " 136",
      name: " JANSEN Amund Grøndahl",
      team: " Team BikeExchange",
    },
    {
      number: " 135",
      name: " HAMILTON Lucas",
      team: " Team BikeExchange",
    },
    {
      number: " 134",
      name: " GROVES Kaden",
      team: " Team BikeExchange",
    },
    {
      number: " 133",
      name: " EDMONDSON Alex",
      team: " Team BikeExchange",
    },
    {
      number: " 132",
      name: " DURBRIDGE Luke",
      team: " Team BikeExchange",
    },
    {
      number: " 131",
      name: " MATTHEWS Michael",
      team: " Team BikeExchange",
    },
    {
      number: " 127",
      name: " WALLAYS Jelle",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 126",
      name: " PÉRICHON Pierre-Luc",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 125",
      name: " PEREZ Anthony",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 124",
      name: " LAPORTE Christophe",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 123",
      name: " GESCHKE Simon",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 122",
      name: " ALLEGAERT Piet",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 121",
      name: " MARTIN Guillaume",
      team: " Cofidis, Solutions Crédits",
    },
    {
      number: " 117",
      name: " SÉNÉCHAL Florian",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 116",
      name: " MØRKØV Michael",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 115",
      name: " LAMPAERT Yves",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 114",
      name: " DECLERCQ Tim",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 113",
      name: " CAVAGNA Rémi",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 112",
      name: " CATTANEO Mattia",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 111",
      name: " BENNETT Sam",
      team: " Deceuninck - Quick Step",
    },
    {
      number: " 107",
      name: " SWIFT Connor",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 106",
      name: " RUSSO Clément",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 105",
      name: " MCLAY Daniel",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 104",
      name: " DELAPLACE Anthony",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 103",
      name: " BOUHANNI Nacer",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 102",
      name: " BOUET Maxime",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 101",
      name: " BARGUIL Warren",
      team: " Team Arkéa Samsic",
    },
    {
      number: " 97",
      name: " VERMEERSCH Florian",
      team: " Lotto Soudal",
    },
    {
      number: " 96",
      name: " VANHOUCKE Harm",
      team: " Lotto Soudal",
    },
    {
      number: " 95",
      name: " OLDANI Stefano",
      team: " Lotto Soudal",
    },
    {
      number: " 94",
      name: " GOOSSENS Kobe",
      team: " Lotto Soudal",
    },
    {
      number: " 93",
      name: " DEGENKOLB John",
      team: " Lotto Soudal",
    },
    {
      number: " 92",
      name: " DE GENDT Thomas",
      team: " Lotto Soudal",
    },
    {
      number: " 91",
      name: " GILBERT Philippe",
      team: " Lotto Soudal",
    },
    {
      number: " 87",
      name: " SINKELDAM Ramon",
      team: " Groupama - FDJ",
    },
    {
      number: " 86",
      name: " SCOTSON Miles",
      team: " Groupama - FDJ",
    },
    {
      number: " 85",
      name: " KONOVALOVAS Ignatas",
      team: " Groupama - FDJ",
    },
    {
      number: " 84",
      name: " GUARNIERI Jacopo",
      team: " Groupama - FDJ",
    },
    {
      number: " 83",
      name: " DÉMARE Arnaud",
      team: " Groupama - FDJ",
    },
    {
      number: " 82",
      name: " ARMIRAIL Bruno",
      team: " Groupama - FDJ",
    },
    {
      number: " 81",
      name: " GAUDU David",
      team: " Groupama - FDJ",
    },
    {
      number: " 77",
      name: " THEUNS Edward",
      team: " Trek - Segafredo",
    },
    {
      number: " 76",
      name: " STUYVEN Jasper",
      team: " Trek - Segafredo",
    },
    {
      number: " 75",
      name: " MOSCA Jacopo",
      team: " Trek - Segafredo",
    },
    {
      number: " 74",
      name: " KIRSCH Alex",
      team: " Trek - Segafredo",
    },
    {
      number: " 73",
      name: " ELISSONDE Kenny",
      team: " Trek - Segafredo",
    },
    {
      number: " 72",
      name: " BERNARD Julien",
      team: " Trek - Segafredo",
    },
    {
      number: " 71",
      name: " PEDERSEN Mads",
      team: " Trek - Segafredo",
    },
    {
      number: " 67",
      name: " SOBRERO Matteo",
      team: " Astana - Premier Tech",
    },
    {
      number: " 66",
      name: " SÁNCHEZ Luis León",
      team: " Astana - Premier Tech",
    },
    {
      number: " 65",
      name: " LUTSENKO Alexey",
      team: " Astana - Premier Tech",
    },
    {
      number: " 64",
      name: " IZAGIRRE Ion",
      team: " Astana - Premier Tech",
    },
    {
      number: " 63",
      name: " FRAILE Omar",
      team: " Astana - Premier Tech",
    },
    {
      number: " 62",
      name: " BATTISTELLA Samuele",
      team: " Astana - Premier Tech",
    },
    {
      number: " 61",
      name: " VLASOV Aleksandr",
      team: " Astana - Premier Tech",
    },
    {
      number: " 57",
      name: " WALSCHEID Max",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 56",
      name: " PELUCCHI Matteo",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 55",
      name: " NIZZOLO Giacomo",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 54",
      name: " CAMPENAERTS Victor",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 53",
      name: " ARU Fabio",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 52",
      name: " ARMÉE Sander",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 51",
      name: " HENAO Sergio",
      team: " Team Qhubeka ASSOS",
    },
    {
      number: " 47",
      name: " VAN BAARLE Dylan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 46",
      name: " SWIFT Ben",
      team: " INEOS Grenadiers",
    },
    {
      number: " 45",
      name: " GEOGHEGAN HART Tao",
      team: " INEOS Grenadiers",
    },
    {
      number: " 44",
      name: " DENNIS Rohan",
      team: " INEOS Grenadiers",
    },
    {
      number: " 43",
      name: " DE PLUS Laurens",
      team: " INEOS Grenadiers",
    },
    {
      number: " 42",
      name: " AMADOR Andrey",
      team: " INEOS Grenadiers",
    },
    {
      number: " 41",
      name: " PORTE Richie",
      team: " INEOS Grenadiers",
    },
    {
      number: " 37",
      name: " VAN EMDEN Jos",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 36",
      name: " OOMEN Sam",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 35",
      name: " MARTIN Tony",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 34",
      name: " KRUIJSWIJK Steven",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 33",
      name: " HOFSTEDE Lennard",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 32",
      name: " BENNETT George",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 31",
      name: " ROGLIČ Primož",
      team: " Team Jumbo-Visma",
    },
    {
      number: " 27",
      name: " RUTSCH Jonas",
      team: " EF Education - Nippo",
    },
    {
      number: " 26",
      name: " POWLESS Neilson",
      team: " EF Education - Nippo",
    },
    {
      number: " 25",
      name: " KEUKELEIRE Jens",
      team: " EF Education - Nippo",
    },
    {
      number: " 24",
      name: " EL FARES Julien",
      team: " EF Education - Nippo",
    },
    {
      number: " 23",
      name: " BISSEGGER Stefan",
      team: " EF Education - Nippo",
    },
    {
      number: " 22",
      name: " ARROYAVE Daniel",
      team: " EF Education - Nippo",
    },
    {
      number: " 21",
      name: " CORT Magnus",
      team: " EF Education - Nippo",
    },
    {
      number: " 17",
      name: " SÜTTERLIN Jasha",
      team: " Team DSM",
    },
    {
      number: " 16",
      name: " PEDERSEN Casper",
      team: " Team DSM",
    },
    {
      number: " 15",
      name: " KRAGH ANDERSEN Søren",
      team: " Team DSM",
    },
    {
      number: " 14",
      name: " HINDLEY Jai",
      team: " Team DSM",
    },
    {
      number: " 13",
      name: " EEKHOFF Nils",
      team: " Team DSM",
    },
    {
      number: " 12",
      name: " BOL Cees",
      team: " Team DSM",
    },
    {
      number: " 11",
      name: " BENOOT Tiesj",
      team: " Team DSM",
    },
    {
      number: " 7",
      name: " SCHWARZMANN Michael",
      team: " BORA - hansgrohe",
    },
    {
      number: " 6",
      name: " POLITT Nils",
      team: " BORA - hansgrohe",
    },
    {
      number: " 5",
      name: " MEEUS Jordi",
      team: " BORA - hansgrohe",
    },
    {
      number: " 4",
      name: " GROßSCHARTNER Felix",
      team: " BORA - hansgrohe",
    },
    {
      number: " 3",
      name: " BENEDETTI Cesare",
      team: " BORA - hansgrohe",
    },
    {
      number: " 2",
      name: " ACKERMANN Pascal",
      team: " BORA - hansgrohe",
    },
    {
      number: " 1",
      name: " SCHACHMANN Maximilian",
      team: " BORA - hansgrohe",
    },
  ]

  const race = "Paris-Nice"

  return (
    <RacepageNew title="Paris - Nice 2021" startlist={startlist} race={race} />
  )
}

export default ParisNice2021
